import { defineComponent } from "vue";
import { withInstall } from "@icons-vue/utils";

const StockOutTSX = defineComponent({
	name: "StockOut",
	render() {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M427.008 185.344h165.376c27.648 0 49.664-22.528 49.664-49.664 0-27.648-22.016-49.664-49.664-49.664H427.008c-27.648 0-49.664 22.528-49.664 49.664 0.512 27.648 22.528 49.664 49.664 49.664z" p-id="11315"></path><path d="M788.992 118.784h-33.28c-18.432 0-33.28 14.848-33.28 33.28s14.848 33.28 33.28 33.28h33.28c36.352 0 66.048 29.696 66.048 66.56v552.96c0 36.864-29.696 66.56-66.048 66.56H235.008c-36.352 0-66.048-29.696-66.048-66.56v-552.96c0-36.864 29.696-66.56 66.048-66.56h33.28c18.432 0 33.28-14.848 33.28-33.28s-14.848-33.28-33.28-33.28h-33.28c-73.216 0-132.096 59.392-132.096 133.12v552.448c0 73.216 59.392 133.12 132.096 133.12H788.48c72.704 0 132.096-59.904 132.096-133.12V251.904c0.512-73.216-58.368-133.12-131.584-133.12z" p-id="11316"></path><path d="M644.096 328.704h49.152v182.784h-156.16v176.64h132.608v-135.68h48.64v206.848h-48.64v-24.576H354.304v24.576h-48.64v-206.848h48.64v135.68h132.608V511.488H330.752V328.704h49.152v135.68h107.008V289.792h50.176v174.592h107.008V328.704z" p-id="11317"></path></svg>
		);
	},
});

export const StockOut = withInstall(StockOutTSX);
export default StockOut;
