import type { Plugin } from "vue";
import { About } from "@icons-vue/icons/about";
import { Backup } from "@icons-vue/icons/backup";
import { BaseInfo } from "@icons-vue/icons/baseInfo";
import { Clear } from "@icons-vue/icons/clear";
import { Component } from "@icons-vue/icons/component";
import { Customers } from "@icons-vue/icons/customers";
import { Dark } from "@icons-vue/icons/dark";
import { Dashboard } from "@icons-vue/icons/dashboard";
import { Dev } from "@icons-vue/icons/dev";
import { Exit } from "@icons-vue/icons/exit";
import { FullScreen } from "@icons-vue/icons/fullScreen";
import { FullScreenExit } from "@icons-vue/icons/fullScreenExit";
import { Light } from "@icons-vue/icons/light";
import { Link } from "@icons-vue/icons/link";
import { Menu } from "@icons-vue/icons/menu";
import { NotData } from "@icons-vue/icons/notData";
import { Organization } from "@icons-vue/icons/organization";
import { Page403 } from "@icons-vue/icons/page403";
import { Page404 } from "@icons-vue/icons/page404";
import { ReportCenter } from "@icons-vue/icons/reportCenter";
import { StockIn } from "@icons-vue/icons/stockIn";
import { StockOut } from "@icons-vue/icons/stockOut";
import { SystemSetting } from "@icons-vue/icons/systemSetting";
import { WarehouseJob } from "@icons-vue/icons/warehouseJob";

export default [
	About,
	Backup,
	BaseInfo,
	Clear,
	Component,
	Customers,
	Dark,
	Dashboard,
	Dev,
	Exit,
	FullScreen,
	FullScreenExit,
	Light,
	Link,
	Menu,
	NotData,
	Organization,
	Page403,
	Page404,
	ReportCenter,
	StockIn,
	StockOut,
	SystemSetting,
	WarehouseJob,
] as Plugin[];

export * from "@icons-vue/icons";
