import { defineComponent } from "vue";
import { withInstall } from "@icons-vue/utils";

const LinkTSX = defineComponent({
	name: "Link",
	render() {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">	<path		d="M931.9 91.4C878.4 37.7 804.3 6.9 728.4 6.9c-75.8 0-149.9 30.8-203.4 84.5l-68.5 68.5c-5.1 4.9-9.9 10-14.4 15.5-12.5 15.1-10.3 37.5 4.8 50 15.1 12.4 37.5 10.4 50-4.8 2.7-3.3 5.6-6.4 9.2-9.9l69.2-69.1c80.7-80.9 223.1-83.5 308.9 2.7 40.4 40.2 63.6 96.1 63.6 153.1s-23.2 112.9-63.7 153.2L748.6 586.2c-2.8 2.8-5.8 5.5-9.6 8.8-33.9 30.1-76.1 48.8-120.9 53.9l-23.6-.1c-57.7-.1-111.8-22.7-152.6-63.6L409 552.3c-13.9-13.9-36.4-13.9-50.2 0-13.9 13.8-13.9 36.4 0 50.2l32.8 32.8c48.4 48.7 111.1 77.8 178.7 83.5l1.1 2.6 23.8-.1c70.4-.4 138.2-26.4 190-72.4 4.9-4.1 9.6-8.4 13.7-12.7l135.3-135.4c53.8-53.5 84.6-127.6 84.6-203.5s-30.7-150-86.9-205.9z"	/>	<path		d="M527.7 805.5c-2.7 3.3-5.6 6.4-9.2 9.9l-69.2 69.2c-40.7 41-94.9 63.5-152.7 63.5h-.4c-56.8 0-112.4-23.1-155.2-66.2-40.4-40.2-63.6-96.1-63.6-153.1 0-57.9 22.6-112.3 63.7-153.2L276.7 440c2.8-2.8 5.7-5.5 9.6-8.8 85.4-75.6 215.8-71.8 296 8.7l33 33.8c13.9 14.1 36.3 14.3 50.2.6 14.1-13.7 14.4-36.2.7-50.2L632.8 390c-107.2-107.3-280.2-112.5-392.9-12.7-4.9 4.1-9.4 8.4-13.7 12.7L90.9 525.3C36.4 579.6 6.3 651.8 6.3 728.8c0 75.9 30.8 150 87 205.9 53.3 53.7 127.2 84.5 202.8 84.5h.6c76.8 0 148.9-30 202.9-84.4l68.5-68.5c5.1-4.9 9.9-10 14.4-15.5 12.5-15.1 10.3-37.5-4.8-50-15.2-12.5-37.5-10.4-50 4.7z"	/></svg>
		);
	},
});

export const Link = withInstall(LinkTSX);
export default Link;
