import { defineComponent } from "vue";
import { withInstall } from "@icons-vue/utils";

const ExitTSX = defineComponent({
	name: "Exit",
	render() {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">	<path		d="M936.96 998.4H364.032c-28.672 0-51.2-23.04-51.2-51.2s23.04-51.2 51.2-51.2h521.216V128.512H364.032c-28.672 0-51.2-23.04-51.2-51.2s23.04-51.2 51.2-51.2H936.96c28.672 0 51.2 23.04 51.2 51.2V947.2c0 28.16-23.04 51.2-51.2 51.2z"	></path>	<path		d="M280.576 755.2c-13.312 0-26.112-5.12-36.352-14.848L51.2 547.84c-9.728-9.728-15.36-23.04-15.36-36.352a51.2 51.2 0 0 1 15.36-36.352L244.736 284.16a51.149 51.149 0 0 1 72.704 0.512c19.968 19.968 19.968 52.736-0.512 72.704L160.256 511.488l156.672 155.648c19.968 19.968 20.48 52.736 0 72.704-9.728 10.24-23.04 15.36-36.352 15.36z"	></path>	<path		d="M606.72 563.2H118.784c-28.672 0-51.2-23.04-51.2-51.2s23.04-51.2 51.2-51.2H606.72c28.672 0 51.2 23.04 51.2 51.2s-22.528 51.2-51.2 51.2z"	></path></svg>
		);
	},
});

export const Exit = withInstall(ExitTSX);
export default Exit;
