import { defineComponent } from "vue";
import { withInstall } from "@icons-vue/utils";

const StockInTSX = defineComponent({
	name: "StockIn",
	render() {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M427.008 185.344h165.376c27.648 0 49.664-22.528 49.664-49.664 0-27.648-22.016-49.664-49.664-49.664H427.008c-27.648 0-49.664 22.528-49.664 49.664 0.512 27.648 22.528 49.664 49.664 49.664z" p-id="7685"></path><path d="M788.992 118.784h-33.28c-18.432 0-33.28 14.848-33.28 33.28s14.848 33.28 33.28 33.28h33.28c36.352 0 66.048 29.696 66.048 66.56v552.96c0 36.864-29.696 66.56-66.048 66.56H235.008c-36.352 0-66.048-29.696-66.048-66.56v-552.96c0-36.864 29.696-66.56 66.048-66.56h33.28c18.432 0 33.28-14.848 33.28-33.28s-14.848-33.28-33.28-33.28h-33.28c-73.216 0-132.096 59.392-132.096 133.12v552.448c0 73.216 59.392 133.12 132.096 133.12H788.48c72.704 0 132.096-59.904 132.096-133.12V251.904c0.512-73.216-58.368-133.12-131.584-133.12z" p-id="7686"></path><path d="M401.408 315.904l40.448-25.6c43.008 30.208 80.896 87.552 114.176 172.544 48.64 119.808 111.616 204.288 189.44 254.464l-30.208 38.4c-80.384-48.128-147.456-140.288-201.728-276.992-1.536-3.584-2.56-6.656-3.584-9.728-34.816 123.904-103.936 219.648-207.36 287.232l-29.184-41.472c106.496-70.144 176.64-171.008 210.432-302.08-22.528-41.472-50.176-73.728-82.432-96.768z" p-id="7687"></path></svg>
		);
	},
});

export const StockIn = withInstall(StockInTSX);
export default StockIn;
