import { defineComponent } from "vue";
import { withInstall } from "@icons-vue/utils";

const ComponentTSX = defineComponent({
	name: "Component",
	render() {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M64 64h384v384H64V64z m0 512h384v384H64V576z m512 0h384v384H576V576z m192-128c106.039 0 192-85.961 192-192S874.039 64 768 64s-192 85.961-192 192 85.961 192 192 192z" p-id="3483"></path></svg>
		);
	},
});

export const Component = withInstall(ComponentTSX);
export default Component;
