import { defineComponent } from "vue";
import { withInstall } from "@icons-vue/utils";

const WarehouseJobTSX = defineComponent({
	name: "WarehouseJob",
	render() {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path d="M469.004 80.973c25.165-19.121 59.901-19.287 85.23-0.51l0.764 0.575 391.51 298.408c11.859 9.04 14.145 25.981 5.106 37.84-8.95 11.742-25.643 14.1-37.484 5.375l-0.357-0.268-391.51-298.408a17 17 0 0 0-20.278-0.247l-0.311 0.231-392.785 298.449c-11.873 9.021-28.811 6.71-37.833-5.163-8.931-11.755-6.755-28.474 4.81-37.56l0.353-0.274L469.004 80.973z" p-id="1533"></path><path d="M206 346v498.877c0 33.352 26.767 60.453 59.991 60.992l1.009 0.008h490.047c33.353 0 60.453-26.767 60.992-59.992l0.008-1.008V346h54v498.877c0 62.877-50.463 113.969-113.098 114.984l-1.902 0.016H267c-62.878 0-113.97-50.463-114.985-113.099l-0.015-1.901V346h54z" p-id="1534"></path><path d="M451 617c24.3 0 44 19.7 44 44v157c0 24.3-19.7 44-44 44H294c-24.3 0-44-19.7-44-44V661c0-24.3 19.7-44 44-44h157z m292 0c24.3 0 44 19.7 44 44v157c0 24.3-19.7 44-44 44H586c-24.3 0-44-19.7-44-44V661c0-24.3 19.7-44 44-44h157z m-302 54H304v137h137V671z m292 0H596v137h137V671zM451 328c24.3 0 44 19.7 44 44v157c0 24.3-19.7 44-44 44H294c-24.3 0-44-19.7-44-44V372c0-24.3 19.7-44 44-44h157z m-10 54H304v137h137V382z" p-id="1535"></path></svg>
		);
	},
});

export const WarehouseJob = withInstall(WarehouseJobTSX);
export default WarehouseJob;
